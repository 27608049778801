// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-project-jsx": () => import("./../../src/layouts/Project.jsx" /* webpackChunkName: "component---src-layouts-project-jsx" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-success-js": () => import("./../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

